import { default as _91id_93huBg3nt9i4Meta } from "/codebuild/output/src372582744/src/mosaic-land/pages/import/[id].vue?macro=true";
import { default as indexYKncvCkjP0Meta } from "/codebuild/output/src372582744/src/mosaic-land/pages/import/index.vue?macro=true";
import { default as indexyRJ8CmauwaMeta } from "/codebuild/output/src372582744/src/mosaic-land/pages/index.vue?macro=true";
import { default as loginjl13ENlq5KMeta } from "/codebuild/output/src372582744/src/mosaic-land/pages/login.vue?macro=true";
import { default as neww2PjXDLzVLMeta } from "/codebuild/output/src372582744/src/mosaic-land/pages/trade/new.vue?macro=true";
export default [
  {
    name: _91id_93huBg3nt9i4Meta?.name ?? "import-id",
    path: _91id_93huBg3nt9i4Meta?.path ?? "/import/:id()",
    meta: _91id_93huBg3nt9i4Meta || {},
    alias: _91id_93huBg3nt9i4Meta?.alias || [],
    redirect: _91id_93huBg3nt9i4Meta?.redirect,
    component: () => import("/codebuild/output/src372582744/src/mosaic-land/pages/import/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYKncvCkjP0Meta?.name ?? "import",
    path: indexYKncvCkjP0Meta?.path ?? "/import",
    meta: indexYKncvCkjP0Meta || {},
    alias: indexYKncvCkjP0Meta?.alias || [],
    redirect: indexYKncvCkjP0Meta?.redirect,
    component: () => import("/codebuild/output/src372582744/src/mosaic-land/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexyRJ8CmauwaMeta?.name ?? "index",
    path: indexyRJ8CmauwaMeta?.path ?? "/",
    meta: indexyRJ8CmauwaMeta || {},
    alias: indexyRJ8CmauwaMeta?.alias || [],
    redirect: indexyRJ8CmauwaMeta?.redirect,
    component: () => import("/codebuild/output/src372582744/src/mosaic-land/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginjl13ENlq5KMeta?.name ?? "login",
    path: loginjl13ENlq5KMeta?.path ?? "/login",
    meta: loginjl13ENlq5KMeta || {},
    alias: loginjl13ENlq5KMeta?.alias || [],
    redirect: loginjl13ENlq5KMeta?.redirect,
    component: () => import("/codebuild/output/src372582744/src/mosaic-land/pages/login.vue").then(m => m.default || m)
  },
  {
    name: neww2PjXDLzVLMeta?.name ?? "trade-new",
    path: neww2PjXDLzVLMeta?.path ?? "/trade/new",
    meta: neww2PjXDLzVLMeta || {},
    alias: neww2PjXDLzVLMeta?.alias || [],
    redirect: neww2PjXDLzVLMeta?.redirect,
    component: () => import("/codebuild/output/src372582744/src/mosaic-land/pages/trade/new.vue").then(m => m.default || m)
  }
]